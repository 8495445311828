<template>
    <div class="share" ref="share">
        <!-- 头部导航 -->
        <HeaderBar title="分享好友砍价" style="z-index:99999" />
        <div class="shareBox">
            <div id="capture">
                <div class="share-img">
                    <div class="logo">
                        <img src="@/assets/haggle/logo.png" alt="">
                    </div>
                    <img class="pic" :src="goodsImg" alt="">
                    <div class="img-tip">
                        <img src="@/assets/haggle/share-bg.png" alt="">
                        <div class="tip-price">
                            <span>最低价</span><br>
                            <span>${{goods.sales_price}}</span>
                        </div>
                    </div>
                </div>
                <div class="share-txt">
                    <div class="share-price">
                        <p class="van-multi-ellipsis--l1">{{goods.name}}</p>
                        <div class="discount">
                            <span>最低价</span>
                            <span>${{goods.sales_price}}<span>${{goods.price}}</span></span>
                        </div>
                    </div>
                    <div class="code-tips">
                        <div class="qrcode">
                            <div class="qrCodeDiv" ref="qrCodeDiv"></div>
                        </div>
                        <div class="tips">
                            <p>
                                <span>用户</span>
                                <!-- <img src="@/assets/bargain/mooby.png" alt=""> -->
                                <span v-if="goods.phone"><span>{{goods.phone}}</span>，邀请您帮他砍价</span>
                                <span v-else><span>{{goods.email}}</span>，邀请您帮他砍价</span>
                            </p>
                            <p>扫码左侧二维码马上砍价</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dashed">
                <div class="left">
                    <img src="@/assets/bargain/save.png" alt="">
                    <p>长按保存图片<br>分享给好友</p>
                </div>
                <div class="line"></div>
                <div class="right">
                    <img src="@/assets/bargain/qecode.png" alt="">
                    <p>好友扫码<br>即可帮你砍价</p>
                </div>
            </div>
            <div class="showShare">
                <img :src="imgSrc" alt="" v-show="isShow">
            </div>
        </div>
    </div>
</template>

<script>
import html2canvas from 'html2canvas'
import QRCode from 'qrcodejs2'
import HeaderBar from '@/components/zh/m-header.vue'
import { getImg } from '@/api/zh/detail.js'
export default {
    name: 'share',
    components: { HeaderBar },
    data(){
        return {
            shareData:{},
            goodsImg: '',
            imgSrc: '',
            isShow: false,
            goods: {}
        }
    },
    methods:{
        getImgHandle() {
            getImg(this.goods.id,{barcode: this.goods.barcode}).then(res => {
                if(res) {
                    this.goodsImg = res.data
                    
                    setTimeout(() => {
                        this.downloadImg()
                    }, 100)
                }
            })
        },
        getData() {
            this.goods = JSON.parse(sessionStorage.getItem('haggleShare'))
            if(this.goods.phone) {
                this.goods.phone = '******' + this.goods.phone.slice(-4)
            }
            if(this.goods.email) {
                this.goods.email =  this.goods.email.substr(0, this.goods.email.indexOf("@")) + '*****' 
            }
            this.getImgHandle()
            this.$nextTick( () => { 
                setTimeout(() => {
                    new QRCode(this.$refs.qrCodeDiv, {
                        text: `https://m.moobyyoho.com/zh/haggleHelp?id=` + this.$route.query.id,
                        width: 55,
                        height: 55,
                        colorDark: "#333333", //二维码颜色
                        colorLight: "#ffffff", //二维码背景色
                        correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
                    })
                },300)
            })
        },
        downloadImg() {
            let that = this
            html2canvas(document.querySelector("#capture")).then((canvas) => {
                var timer = setTimeout(() => {
                    var imgData = canvas.toDataURL('image/jpeg');//canvas转换为图片
                    that.imgSrc = imgData
                    that.isShow = true
                    clearTimeout(timer)
                }, 0)
            }).catch(error => {
                console.log(error, 'error')
            })
            this.$store.commit('unload')
        }
    },
    created() {
        this.$store.commit('load')
        this.getData()
    },
}
</script>
<style lang="less" scope>
.share {
    position: relative;
    width:100%;
    height:100%;
    overflow: hidden;
    .shareBox {
        position: absolute;
        top: 7%;
        left: 50%;
        transform: translateX(-50%);
        width: 343px;
        height: 590px;
        border-radius: 22px 22px 8px 8px;
        border: 1px solid #FF4745;
        padding: 8px 8px 0;
        #capture {
            width: 327px;
            position: absolute;
            top: 8px;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 12px;
            overflow: hidden;
            box-shadow: 0px 2px 8px rgba(233, 205, 205, 0.3);
            .share-img {
                width: 100%;
                height: 327px;
                position: relative;
                .logo {
                    position: absolute;
                    top: 6px;
                    left: 5px;
                    width: 114px;
                    height: 26px;
                    text-align: center;
                    line-height: 26px;
                    border-radius: 13px;
                    z-index: 2;
                    background: #fff;
                    >img {
                        width: 100.5px;
                        height: 22.5px;
                    }
                }
                .pic {
                    width: 100%;
                    height: 100%;
                }
                .img-tip {
                    position: absolute;
                    width: 100%;
                    height: 77px;
                    width: 100%;
                    bottom: 0;
                    left: 0;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                    .tip-price {
                        position: absolute;
                        right: 8px;
                        top: 21px;
                        color: #fff;
                        font-weight: 700;
                        text-align: center;
                        >span:nth-child(1) {
                            font-size: 14px;
                        }
                        >span:nth-child(3) {
                            font-size: 20px;
                        }
                    }
                }
            }
            .share-txt {
                padding: 8px 12px 20px;
                .share-price {
                    width: 100%;
                    >p {
                        letter-spacing: 0.36px;
                    }
                    >.discount {
                        margin-top: 8px;
                        margin-bottom: 12px;
                        display: flex;
                        align-items:center;
                        &>span:nth-child(1) {
                            display: inline-block;
                            width: 42px;
                            text-align: center;
                            color: #fff;
                            font-size: 10px;
                            background: linear-gradient(315deg, #F03C18 0.05%, #F06448 100%);
                            border-radius: 2px;
                            margin-right: 5px;
                        }
                        &>span:nth-child(2) {
                            color: #ED2A24;
                            font-size: 18px;
                            &>span {
                                margin-left: 5px;
                                font-size: 10px;
                                color: #999999;
                                text-decoration:line-through;
                            }
                        }
                    }
                }
                .code-tips {
                    border-top: 1px solid #f2f2f2;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-top: 16px;
                    .qrcode {
                        width: 80px;
                        height: 80px;
                        flex-shrink: 0;
                        border-radius: 6px;
                        margin-right: 12px;
                        background: #F3F3F3;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        &>div {
                            width: 55px !important;
                            height: 55px !important;
                            img {
                                width: 55px !important;
                                height: 55px !important;
                            }
                        }
                    }
                    .tips {
                        font-size: 16px;
                        p {
                            &>span:nth-child(2) {
                                span {
                                    margin:0 4px;
                                    color: #0093FF;
                                }
                            }
                        }
                        &>p:nth-child(2) {
                            color: #0093FF;
                        }
                    }
                }
            }
        }
        .dashed {
            position: absolute;
            bottom: 12px;
            left: 50%;
            transform: translateX(-50%);
            z-index:100;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: not-allowed;
            .left,.right {
                display: flex;
                align-items: center;
                img {
                    width: 38px;
                    height: 38px;
                    margin-right: 4px;
                }
                p {
                    width: 86px;
                    font-size: 14px;
                    color: #666;
                }
            }
            .line {
                background: #FFCAB3;
                width: 1px;
                height: 32px;
                margin: 0 12px;
            }
        }
        
    }
    .showShare{
        position: absolute;
        top: 8px;
        left: 50%;
        width: 327px;
        height: 513px;
        overflow: hidden;
        border-radius: 12px;
        transform: translateX(-50%);
        z-index:100;
        background: #FEFAFA;
        box-shadow: 0px 2px 8px rgba(233, 205, 205, 0.3);
        img {
            width: 100%;
            height: 100%;
        }
    }
    
}
</style>